<template>
  <div class="login-page-wrapper">
    <div class="page-inner-wrapper">
      <div class="form-title-wrapper">
        <!-- <CustomizeTabs v-model="activeName">
          <CustomizeTabItem lable="Log In" name="fist"></CustomizeTabItem>
          <CustomizeTabItem lable="Sign Up" name="second"></CustomizeTabItem>
          <template #content>
            <div>{{ activeName }}</div>
          </template>
        </CustomizeTabs> -->
        <ul class="serices-tabs">
          <li
            class="tab-item"
            :class="[tabTag == 'login' ? 'active' : '']"
            @click="changeTab('login')"
          >
            <p class="tab-text">Log In</p>
            <span class="line">
              <span class="inner-line"></span>
            </span>
          </li>
          <li
            class="tab-item"
            :class="[tabTag == 'signup' ? 'active' : '']"
            @click="changeTab('signup')"
          >
            <p class="tab-text">Sign Up</p>
            <span class="line">
              <span class="inner-line"></span>
            </span>
          </li>
        </ul>
      </div>
      <div class="instance-content-wrapper">
        <div class="form-content-wrapper">
          <el-form
            :hide-required-asterisk="true"
            :model="ruleForm"
            :rules="rules"
            ref="formRef"
            class="login-form"
            label-position="top"
            @submit.native.prevent
          >
            <div class="email-container from-item-container">
              <el-form-item label="Email" prop="email">
                <el-input
                  ref="emailInputRef"
                  type="text"
                  v-model="ruleForm.email"
                  placeholder="Please input email"
                />
              </el-form-item>
            </div>
            <div
              class="verify-code-container from-item-container"
              v-if="tabTag === 'signup'"
            >
              <el-form-item label="Code" prop="verifyCode">
                <div class="code-inner-container">
                  <div class="code-input-content">
                    <el-input
                      ref="verifyCodeInputRef"
                      type="text"
                      v-model="ruleForm.verifyCode"
                      placeholder="Please input verify code"
                      maxlength="8"
                    >
                    </el-input>
                  </div>
                  <div class="send-code-content">
                    <SendCode
                      :sendFunction="sendEmailCode"
                      :fontColor="'#FFFFFF'"
                    />
                  </div>
                </div>
              </el-form-item>
            </div>
            <div class="password-container from-item-container">
              <el-form-item label="Password" prop="password">
                <InputPassword v-model="ruleForm.password">
                  <template #default="scope">
                    <el-input
                      ref="passwordInputRef"
                      :type="scope.showPassword ? 'text' : 'password'"
                      v-model="ruleForm.password"
                      placeholder="Please input password"
                      maxlength="254"
                      autocomplete="new-password"
                    />
                  </template>
                </InputPassword>
              </el-form-item>
            </div>
            <div class="forget-password-container">
              <a
                class="forget-password-text"
                href="/forget"
                v-if="tabTag === 'login'"
              >
                <div class="text-row">Forget password?</div>
                <span class="line">
                  <span class="inner-line"></span>
                </span>
              </a>
            </div>
          </el-form>
        </div>
        <div class="login-button" @click="submitFormHandle">
          {{ tabTag == "login" ? `Log In` : `Sign Up` }}
        </div>
        <div class="split-line">Or</div>
        <div class="use-google-button" @click="() => login()">
          <div class="google-icon-wrapper">
            <GoogleIcon />
          </div>
          <div class="text-wrapper">Log In with Google</div>
        </div>
        <div class="bottom-link-wrapper">
          <div class="link-item-wp">
            <NuxtLink to="/terms">Terms</NuxtLink>
          </div>
          <div class="link-item-wp">
            <NuxtLink to="/privacy">Privacy</NuxtLink>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import {
  navigateTo,
  onBeforeRouteUpdate,
  reactive,
  ref,
  useDebounce,
  useHead,
  useHttpRequest,
  useNuxtApp,
  useRoute,
  useServerRequest,
  useUserStore,
  validEmail,
  validPassword,
  validVerifyEmailCode,
} from "#imports";
import {
  useCodeClient,
  type ImplicitFlowSuccessResponse,
  type ImplicitFlowErrorResponse,
} from "vue3-google-signin";
import { ElMessage } from "element-plus";
import { onBeforeMount } from "vue";

const userStore = useUserStore();
const route = useRoute();
const nuxtApp = useNuxtApp();

// let activeName = ref("first");
let tabTag = ref("login");
let formRef = ref();
let emailInputRef = ref();
let verifyCodeInputRef = ref();
let passwordInputRef = ref();

tabTag.value = (route.query.scheme || "login") as string;

let ruleForm = reactive({
  email: "",
  password: "",
  verifyCode: "",
});

const validatorEmailHandle = (rule: any, value: string, callback: any) => {
  if (value === "") {
    callback(new Error("Please input email"));
  } else if (!validEmail(value)) {
    callback(new Error("Please enter the correct email format"));
  } else {
    callback();
  }
};

const validatorPasswordHandle = (rule: any, value: string, callback: any) => {
  if (value === "") {
    callback(new Error("Please input password"));
  } else if (!validPassword(value)) {
    callback(
      new Error("The account password must be more than 8 letters + numbers")
    );
  } else {
    callback();
  }
};

const validatorVerifyCodeHandle = (rule: any, value: string, callback: any) => {
  if (value === "") {
    callback(new Error("Please input verify code"));
  } else if (!validVerifyEmailCode(value)) {
    callback(new Error("Please enter valid verification code format"));
  } else {
    callback();
  }
};

let rules = {
  email: [
    {
      validator: validatorEmailHandle,
      trigger: ["blur"],
    },
  ],
  password: [
    {
      validator: validatorPasswordHandle,
      trigger: ["blur"],
    },
  ],
  verifyCode: [
    {
      validator: validatorVerifyCodeHandle,
      trigger: ["blur"],
    },
  ],
};

const changeTab = async (tag: string) => {
  if (tabTag.value == tag) {
    return;
  }
  tabTag.value = tag;
  process.client && formRef.value.resetFields();
  await navigateTo(`/login?scheme=${tag}`);
  useHead({
    title: tabTag.value == "login" ? "Login - RootList" : "Sign Up - RootList",
  });
};

const handleOnCodeSuccess = async (response: ImplicitFlowSuccessResponse) => {
  // send code to a backend server to verify it.
  console.log("Code: ", response);

  LoginWithGoogle(response.code);
};

const handleOnCodeError = (errorResponse: ImplicitFlowErrorResponse) => {
  console.log("Error: ", errorResponse);
};

const { login } = useCodeClient({
  onSuccess: handleOnCodeSuccess,
  onError: handleOnCodeError,
  redirect_uri: `${nuxtApp.$locationOrigin}/login`,
  state: "rl",
  ux_mode: "popup",
  scope: "https://www.googleapis.com/auth/userinfo.email",
  // other options
});

const sendEmailCode = () => {
  if (ruleForm.email === "") {
    process.client && ElMessage.error("Please input email address");
    process.client && emailInputRef.value.focus();
    return Promise.reject(false);
  }
  if (!validEmail(ruleForm.email)) {
    process.client && ElMessage.error("Please enter the correct email format");
    process.client && emailInputRef.value.focus();
    return Promise.reject(false);
  }
  return useHttpRequest("/api/rcode", {
    method: "POST",
    body: { email: ruleForm.email },
  });
};
const submitFormHandle = useDebounce(
  () => {
    if (tabTag.value === "login") {
      logIn();
    } else {
      signUp();
    }
  },
  500,
  {
    leading: false,
    trailing: true,
  }
);

const logIn = () => {
  if (ruleForm.email === "") {
    process.client && ElMessage.error("Please input email address");
    process.client && emailInputRef.value.focus();
    return Promise.reject(false);
  }
  if (!validEmail(ruleForm.email)) {
    process.client && ElMessage.error("Please enter the correct email format");
    process.client && emailInputRef.value.focus();
    return Promise.reject(false);
  }
  if (ruleForm.password === "") {
    process.client && ElMessage.error("Please input password");
    process.client && passwordInputRef.value.focus();
    return Promise.reject(false);
  }
  if (!validPassword(ruleForm.password)) {
    process.client &&
      ElMessage.error(
        "The account password must be more than 8 letters + numbers"
      );
    process.client && passwordInputRef.value.focus();
    return Promise.reject(false);
  }
  let requestParams = {
    email: ruleForm.email,
    password: ruleForm.password,
  };
  useHttpRequest("/api/signin", {
    method: "POST",
    body: requestParams,
  })
    .then(async (res: any) => {
      console.log("sucess:", res);
      if (res.code == 0) {
        let _token = res?.data?.token || "";
        userStore.setToken(_token);
        let _userInfo = res?.data?.profile || {};
        userStore.setUserInfo(_userInfo);
        let _message = "Log in success";
        process.client && ElMessage.success(_message);
        process.client && formRef.value.resetFields();
        await navigateTo(`/`);
      } else {
        process.client && ElMessage.error(res.msg);
      }
    })
    .catch((err: any) => {
      console.log("error:", err);
    });
};

const signUp = () => {
  if (ruleForm.email === "") {
    process.client && ElMessage.error("Please input email address");
    process.client && emailInputRef.value.focus();
    return Promise.reject(false);
  }
  if (!validEmail(ruleForm.email)) {
    process.client && ElMessage.error("Please enter the correct email format");
    process.client && emailInputRef.value.focus();
    return Promise.reject(false);
  }
  if (ruleForm.verifyCode === "") {
    process.client && ElMessage.error("Please input verify code");
    process.client && verifyCodeInputRef.value.focus();
    return Promise.reject(false);
  }
  if (!validVerifyEmailCode(ruleForm.verifyCode)) {
    process.client &&
      ElMessage.error("Please enter valid verification code format");
    process.client && verifyCodeInputRef.value.focus();
    return Promise.reject(false);
  }
  if (ruleForm.password === "") {
    process.client && ElMessage.error("Please input password");
    process.client && passwordInputRef.value.focus();
    return Promise.reject(false);
  }
  if (!validPassword(ruleForm.password)) {
    process.client &&
      ElMessage.error(
        "The account password must be more than 8 letters + numbers"
      );
    process.client && passwordInputRef.value.focus();
    return Promise.reject(false);
  }
  let requestParams = {
    email: ruleForm.email,
    password: ruleForm.password,
    code: ruleForm.verifyCode,
  };
  useHttpRequest("/api/signup", {
    method: "POST",
    body: requestParams,
  })
    .then(async (res: any) => {
      console.log("sucess:", res);
      if (res.code == 0) {
        let _message = "Sign up success";
        process.client && ElMessage.success(_message);
        process.client && formRef.value.resetFields();
        await navigateTo(`/login?scheme=login`);
      } else {
        process.client && ElMessage.error(res.msg);
      }
    })
    .catch((err: any) => {
      console.log("error:", err);
    });
};

const LoginWithGoogle = (code: any) => {
  let requestParams = {
    state: "rl",
    code: code,
    error_reason: "",
  };
  useHttpRequest("/api/google/signin", {
    method: "POST",
    body: requestParams,
  })
    .then(async (res: any) => {
      console.log("sucess:", res);
      if (res.code == 0) {
        let _token = res?.data?.token || "";
        userStore.setToken(_token);
        let _userInfo = res?.data?.profile || {};
        userStore.setUserInfo(_userInfo);
        let _message = "Log in success";
        process.client && ElMessage.success(_message);
        process.client && formRef.value.resetFields();
        await navigateTo(`/`);
      } else {
        process.client && ElMessage.error(res.msg);
      }
    })
    .catch((err: any) => {
      console.log("error:", err);
    });
};

useHead({
  title: tabTag.value == "login" ? "Login - RootList" : "Sign Up - RootList",
});

onBeforeRouteUpdate((to, from) => {
  let { query } = to || {};
  let { scheme } = query || "login";
  tabTag.value = scheme as string;
  useHead({
    title: tabTag.value == "login" ? "Login - RootList" : "Sign Up - RootList",
  });
});

onBeforeMount(async () => {
  if (userStore.isLogin) {
    await navigateTo(`/`);
  }
});
</script>
<style lang="scss" scoped>
.login-page-wrapper {
  padding-top: 40px;
  padding-bottom: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  .page-inner-wrapper {
    width: 400px;
    min-height: 400px;
    .form-title-wrapper {
      height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
      .serices-tabs {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .tab-item {
          min-width: 120px;
          padding-bottom: 20px;
          color: #312f3a;
          font-family: PingFangSC, PingFang SC;
          font-size: 22px;
          font-weight: 700;
          transition: color 0.5s ease;
          position: relative;
          text-align: center;
          cursor: pointer;
          .line {
            background: transparent;
            bottom: 0;
            height: 2px;
            left: 0;
            position: absolute;
            pointer-events: none;
            width: 100%;
            .inner-line {
              background: #1ec174;
              height: 100%;
              left: 0;
              position: absolute;
              transform: scaleX(0);
              transform-origin: left;
              transition: transform 0.5s ease;
              width: 100%;
            }
          }
          &.active {
            color: #1ec174;
            .line {
              background: transparent;
              bottom: 0;
              height: 2px;
              left: 0;
              position: absolute;
              pointer-events: none;
              width: 100%;
              .inner-line {
                transform: scaleX(1);
              }
            }
          }
          &:hover {
            color: #1ec174;
            .line {
              background: transparent;
              bottom: 0;
              height: 2px;
              left: 0;
              position: absolute;
              pointer-events: none;
              width: 100%;
              .inner-line {
                transform: scaleX(1);
              }
            }
          }
        }
      }
    }
    .instance-content-wrapper {
      padding-top: 30px;
      .form-content-wrapper {
        padding-bottom: 10px;
        .from-item-container {
          margin-top: 10px;
          &.password-container {
            margin-top: 20px !important;
          }
          &.verify-code-container {
            margin-top: 20px !important;
          }
          .code-inner-container {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .code-input-content {
              height: 40px;
              flex: 1;
            }
            .send-code-content {
              margin-left: 10px;
              flex-shrink: 0;
              height: 40px;
              min-width: 100px;
              background: #1ec174;
              border-radius: 8px;
            }
          }
        }
        .forget-password-container {
          height: 40px;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          .forget-password-text {
            display: block;
            height: 34px;
            line-height: 34px;
            text-align: right;
            cursor: pointer;
            position: relative;
            font-weight: 400;
            font-size: 14px;
            color: #333333;
            .line {
              background: transparent;
              bottom: 0;
              height: 2px;
              left: 0;
              position: absolute;
              pointer-events: none;
              width: 100%;
              .inner-line {
                background: #1ec174;
                height: 100%;
                left: 0;
                position: absolute;
                transform: scaleX(0);
                transform-origin: left;
                transition: transform 0.5s ease;
                width: 100%;
              }
            }
            &:hover {
              color: #1ec174;
              .line {
                background: transparent;
                bottom: 0;
                height: 2px;
                left: 0;
                position: absolute;
                pointer-events: none;
                width: 100%;
                .inner-line {
                  transform: scaleX(1);
                }
              }
            }
          }
        }
      }
      .login-button {
        cursor: pointer;
        height: 44px;
        line-height: 44px;
        text-align: center;
        border-radius: 12px;
        background: #1ec174;
        font-size: 16px;
        font-weight: 500;
        color: #ffffff;
      }
      .split-line {
        font-size: 16px;
        font-weight: 500;
        color: #0a0e14;
        margin: 16px 0;
        position: relative;
        text-align: center;
        &::before {
          left: 0;
          border: 1px solid #dedede;
          content: "";
          display: block;
          height: 1px;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          width: calc(50% - 40px);
        }
        &::after {
          right: 0;
          border: 1px solid #dedede;
          content: "";
          display: block;
          height: 1px;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          width: calc(50% - 40px);
        }
      }
      .use-google-button {
        height: 44px;
        border: 1px solid #1ec174;
        border-radius: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        .google-icon-wrapper {
          height: 18px;
          width: 18px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 8px;
          svg {
            height: 100%;
            width: 100%;
          }
        }
        .text-wrapper {
          text-align: center;
          font-size: 16px;
          font-weight: 500;
          color: #0a0e14;
        }
      }
      .bottom-link-wrapper {
        padding-top: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        .link-item-wp {
          margin-left: 20px;
          margin-right: 20px;
          a {
            font-size: 14px;
            font-weight: 500;
            color: #1ec174;
            margin-bottom: 16px;
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style>
